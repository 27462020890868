export enum EAppFormFields {
	ID = 'id',
	NAME = 'name',
	SLUG = 'slug',
	NEXT_SLUG = 'nextSlug',
	PLACEHOLDER = 'placeholder',
	BLOCK_NAME = 'blockName',
	URL = 'url',
	URLS = 'urls',
	DESCRIPTION = 'description',
	METHOD = 'method',
	BLOCK_TYPE = 'blockType',
	PROMPT = 'prompt',
	TYPE = 'type',
	TYPES = 'types',
	MODEL = 'model',
	AUTONEXT = 'autonext',
	IS_HIDDEN = 'isHidden',
	AUTHOR = 'author',
	TAGS = 'tags',
	IS_PRIVATE = 'isPrivate',
	IS_FAVOURITE = 'isFavourite',
	LOCALE = 'locale',
	ACTIONS = 'actions',
	BODY = 'body',
	JSONATA = 'jsonata',
	IS_XML = 'isXml',
	HEADERS = 'headers',
	VALUE = 'value',
	EXTRACT_IMAGES = 'extract_images',
	MAX_TOKENS = 'max_tokens',
	CONTENT_CONVERSATION_TYPE = 'contentConversionType',
	ASSISTANT = 'assistant',
	SUCCESS = 'success',
	FAIL = 'fail',
	CONDITION = 'condition',
	PASSED_VALUE = 'passedValue',
	OPERATOR = 'operator',
	ITEM_SLUG = 'itemSlug',
	CALLBACKS = 'callbacks',
	TEMPLATE = 'template',
	EXPRESSION = 'expression',
	TEMPERATURE = 'temperature',
	MESSAGE = 'message',
	EMAILS = 'emails',
	EMOJI = 'emoji',
	VAULT = 'vault',
	PLUGIN = 'plugin',
	TASK = 'task'
}

export type TActionData = {
	[EAppFormFields.SLUG]: string;
	[EAppFormFields.BLOCK_TYPE]: string;
	[EAppFormFields.ID]?: string;
	[EAppFormFields.NAME]?: string;
	[EAppFormFields.PLACEHOLDER]?: string;
	[EAppFormFields.BLOCK_NAME]?: string;
	[EAppFormFields.URL]?: string;
	[EAppFormFields.DESCRIPTION]?: string;
	[EAppFormFields.METHOD]?: string;
	[EAppFormFields.NEXT_SLUG]?: string;
	[EAppFormFields.IS_HIDDEN]?: boolean;
	[EAppFormFields.TYPE]?: TBlockTypes;
	[EAppFormFields.TYPES]?: ['telegram', 'email'];
};

export type TEditAppForm = {
	[EAppFormFields.AUTHOR]: string;
	[EAppFormFields.NAME]: string;
	[EAppFormFields.PLACEHOLDER]?: string;
	[EAppFormFields.DESCRIPTION]?: string;
	[EAppFormFields.TAGS]: string[];
	[EAppFormFields.IS_PRIVATE]?: boolean;
	[EAppFormFields.LOCALE]: string[];
	[EAppFormFields.ACTIONS]: TActionData[];
};

export type TBlockTypes =
	| 'api_call'
	| 'gpt_call'
	| 'initial'
	| 'idea'
	| 'jsonata_call'
	| 'llm_call'
	| 'mailing_call'
	| 'vault_call'
	| 'task_call';

export type TErrorFields = {
	name: (string | number)[];
	errors: string[];
};

export type TActionConfigItem = {
	name: string;
	inputType: 'radio' | 'checkbox' | 'text' | 'textArea' | 'select' | 'multiselect' | 'switch';
	rules: Record<string, any>[];
	isHidden?: boolean;
	label?: string;
	options?: { label: string; value: string | number }[];
};
