import { isLoggedIn } from 'entities/user';
import { Navigate } from 'react-router-dom';
import React, { FC } from 'react';

export const PrivateProvider: FC<{ children: React.ReactElement }> = ({ children }) => {
	const isUserLoggedIn = isLoggedIn.use();

	if (!isUserLoggedIn) return <Navigate to='/' />;

	return children;
};
