import React, { memo, useMemo, useState } from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath, MarkerType } from 'reactflow';
import {
	ApiCallActionIcon,
	GptActionIcon,
	JSONataCallActionIcon,
	LlmActionIcon,
	MailingCallActionIcon,
	PlusOutlined,
	VaultActionIcon,
	ToolFilled
} from 'shared/ui/Icons';
import { PopoverProps } from 'antd';
import { TBlockTypes } from '../../model/types';
import { setActions, setEdges, setIsActionListChanged, updateActionData } from '../../model/slice';
import { useTranslate } from 'shared/lib/i18n';
import { useAppDispatch, useAppSelector } from 'shared/model';
import { selectActions, selectEdges } from '../../model/selectors';
import { generateId } from 'shared/lib';
import { STYLES } from 'shared';
import cn from 'classnames';
import { CustomCollapse, CustomPopover } from 'shared/ui';

const CustomEdgeComponent = ({
	id,
	sourceX,
	sourceY,
	targetX,
	targetY,
	sourcePosition,
	targetPosition,
	style = {},
	markerEnd,
	source,
	target
}: EdgeProps) => {
	const t = useTranslate();
	const dispatch = useAppDispatch();
	const [open, setOpen] = useState(false);
	const actions = useAppSelector(selectActions);
	const edges = useAppSelector(selectEdges);

	const targetAction = useMemo(() => actions.find((action) => action.id === target), [actions]);
	const sourceAction = useMemo(() => actions.find((action) => action.id === source), [actions]);
	const [edgePath, labelX, labelY] = getBezierPath({
		sourceX,
		sourceY,
		sourcePosition,
		targetX,
		targetY,
		targetPosition
	});

	const onChange = (value: TBlockTypes) => {
		if (!!sourceAction && !!targetAction) {
			const sourceIndex = actions?.findIndex((action) => action.id === source);
			const newActionId = `${generateId().substring(0, 5)}`;
			const newActions = actions.map((action) => ({ ...action, selected: false }));
			newActions.splice(sourceIndex + 1, 0, {
				id: newActionId,
				type: value,
				position: {
					x: labelX,
					y: labelY
				},
				selected: true,
				data: {
					slug: newActionId,
					type: 'static_text',
					blockType: value,
					isHidden: true,
					...(value === 'gpt_call'
						? { contentConversionType: 'notConverted', model: 'gpt-4o', temperature: '0.5' }
						: {}),
					...(value === 'llm_call' ? { contentConversionType: 'notConverted', temperature: '0.7' } : {}),
					...(value === 'jsonata_call' ? { expression: '$' } : {}),
					...(value === 'mailing_call' ? { types: { telegram: true, email: true } } : {})
				}
			});
			dispatch(setActions(newActions));
			const updatedEdges = edges
				?.filter((edge) => edge.id !== id)
				.concat([
					{
						id: `${source}->${newActionId}`,
						source: source,
						target: newActionId,
						type: 'customedge',
						markerEnd: {
							type: MarkerType.ArrowClosed,
							color: STYLES.colors.gray_light
						},
						style: {
							stroke: STYLES.colors.gray_light,
							strokeWidth: 2
						}
					},
					{
						id: `${newActionId}->${target}`,
						source: newActionId,
						target: target,
						type: 'customedge',
						markerEnd: {
							type: MarkerType.ArrowClosed,
							color: STYLES.colors.gray_light
						},
						style: {
							stroke: STYLES.colors.gray_light,
							strokeWidth: 2
						}
					}
				]);
			dispatch(setEdges(updatedEdges));
			dispatch(
				updateActionData({ actionId: source, path: ['actions', sourceIndex, 'nextSlug'].join('.'), value: newActionId })
			);
			dispatch(
				updateActionData({
					actionId: newActionId,
					path: ['actions', sourceIndex + 1, 'nextSlug'].join('.'),
					value: target
				})
			);
			dispatch(setIsActionListChanged(true));
		}
	};

	const handleOpenChange: PopoverProps['onOpenChange'] = (newOpen, e) => {
		setOpen(newOpen);
	};

	const selectActionType = (actionType: Parameters<typeof onChange>[0]) => {
		setOpen(false);
		onChange(actionType);
	};

	const content = useMemo(() => {
		return (
			<div className='min-w-[220px] pt-3'>
				<div
					onClick={(e) => {
						e.preventDefault();
						selectActionType('gpt_call');
					}}
					className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
				>
					<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
						<GptActionIcon />
					</div>
					<span>{t('gpt_call')}</span>
				</div>
				<div
					onClick={(e) => {
						e.preventDefault();
						selectActionType('api_call');
					}}
					className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
				>
					<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
						<ApiCallActionIcon />
					</div>
					<span>{t('api_call')}</span>
				</div>
				<div
					onClick={(e) => {
						e.preventDefault();
						selectActionType('mailing_call');
					}}
					className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
				>
					<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
						<MailingCallActionIcon />
					</div>
					<span>{t('mailing_call')}</span>
				</div>
				<div
					onClick={(e) => {
						e.preventDefault();
						selectActionType('vault_call');
					}}
					className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
				>
					<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
						<VaultActionIcon />
					</div>
					<span>{t('vault_call')}</span>
				</div>
				<CustomCollapse
					className='mt-3'
					expandIconPosition={'end'}
					ghost
					items={[
						{
							label: <span className='ml-3 font-medium'>{t('advanced')}</span>,
							children: (
								<>
									<div
										onClick={(e) => {
											e.preventDefault();
											selectActionType('jsonata_call');
										}}
										className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
									>
										<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
											<JSONataCallActionIcon />
										</div>
										<span>JSONata</span>
									</div>
									<div
										onClick={(e) => {
											e.preventDefault();
											selectActionType('llm_call');
										}}
										className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
									>
										<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
											<LlmActionIcon />
										</div>
										<span>LLM</span>
									</div>
									<div
										onClick={(e) => {
											e.preventDefault();
											selectActionType('task_call');
										}}
										className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
									>
										<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
											<ToolFilled className='text-cyan-600' />
										</div>
										<span>{t('task_call')}</span>
									</div>
								</>
							)
						}
					]}
				/>
			</div>
		);
	}, []);

	return (
		<>
			<BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
			{targetAction?.type !== 'initial' && (
				<EdgeLabelRenderer>
					<div
						style={{
							position: 'absolute',
							transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
							pointerEvents: 'all'
						}}
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}
					>
						<CustomPopover
							destroyTooltipOnHide={true}
							trigger='click'
							open={open}
							content={content}
							onOpenChange={handleOpenChange}
							arrow={false}
						>
							<div
								className={cn(
									'w-[8px] h-[8px] flex items-center justify-center rounded-full p-px hover:bg-white cursor-pointer transition-all duration-100 overflow-hidden',
									'hover:w-[20px] hover:h-[20px] hover:border hover:border-dashed hover:rounded',
									{
										'border-gray-light bg-gray-light': style?.stroke === STYLES.colors.gray_light,
										'border-primary bg-primary': style?.stroke === STYLES.colors.primary,
										'!w-[20px] !h-[20px] !border !border-dashed !rounded !bg-white': open
									}
								)}
							>
								<PlusOutlined
									className={cn('text-[8px]', {
										'text-gray-light': style?.stroke === STYLES.colors.gray_light,
										'text-primary': style?.stroke === STYLES.colors.primary
									})}
								/>
							</div>
						</CustomPopover>
					</div>
				</EdgeLabelRenderer>
			)}
		</>
	);
};

export const CustomEdge = memo(CustomEdgeComponent);
