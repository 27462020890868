import React, { FC, memo, useState, ReactNode, useCallback } from 'react';
import cn from 'classnames';
import { EditOutlinedIcon } from 'shared/ui/Icons';
import { Button, Form, Input, Checkbox, Select, message } from 'antd';
import { useTranslate } from '../../../../shared/lib/i18n';
import { TArtefact } from '../../api/types';

type TArtefactDynamicTextProps = {
	onSave: (data: any, id: string) => void;
	artefact: TArtefact<TUserInput>;
	name: string;
	blocked: boolean;
	regenerateBtn?: ReactNode;
	reviewBtn?: ReactNode;
};

type TUserInputField = {
	name: string;
	blockType: 'text' | 'textarea' | 'select' | 'checkbox';
	label?: string;
	required: boolean;
	defaultValue?: any;
	options?: { label: string; value: string }[];
};

type TUserInput = {
	fields: TUserInputField[];
	values?: any;
};

const ArtefactUserInputComponent: FC<TArtefactDynamicTextProps> = ({ onSave, artefact, name, blocked }) => {
	const t = useTranslate();
	const [form] = Form.useForm();
	const { fields, values } = artefact.data;
	const [isDisabled, setIsDisabled] = useState<boolean>(blocked && true);

	const handleEdit = () => {
		setIsDisabled((prev) => !prev);
	};
	const handleCancel = () => {
		form.resetFields();
		setIsDisabled((prev) => !prev);
	};

	const onFinish = useCallback(async () => {
		try {
			const values = await form.validateFields();
			onSave({ fields, values }, artefact.id);
			setIsDisabled(true);
		} catch (e) {
			const error = e as Error;
			message.error({
				content: <span>{error.message}</span>,
				duration: 5
			});
		}
	}, [fields, form]);

	return (
		<div className={cn('rounded-xl bg-gray-bg p-4', { 'border-gray-border border-solid border-[1px]': !isDisabled })}>
			<div className='flex justify-between items-start'>
				<div>
					<p className='text-gray-secondry text-sm'>{name}</p>
					{artefact.metadata.actionDescription && (
						<p className='text-sm mb-7 opacity-25'>{artefact.metadata.actionDescription}</p>
					)}
				</div>
				<div className='dynamic-artefact-controls'>
					<Button
						type='text'
						shape='circle'
						icon={<EditOutlinedIcon className='!text-xl' />}
						onClick={handleEdit}
						disabled={!isDisabled}
					/>
					{!isDisabled && <span className='opacity-40'>{t('edit_mode')}</span>}
				</div>
			</div>
			<Form form={form} colon={false} requiredMark={false}>
				{fields.map((field) => {
					switch (field.blockType) {
						case 'text':
							return (
								<Form.Item
									key={field.name}
									name={field.name}
									label={
										<div className='text-start opacity-65 max-w-[200px] min-w-[200px] text-ellipsis overflow-hidden'>
											<span className='max-w-full whitespace-normal '>{field.label}</span>
											{field.required && <span className='ml-1 text-error'>*</span>}
										</div>
									}
									rules={[{ required: field.required, message: t('error.required_field') }]}
									initialValue={values && values[field.name] ? values[field.name] : field.defaultValue}
								>
									<Input disabled={isDisabled} />
								</Form.Item>
							);
						case 'textarea':
							return (
								<Form.Item
									key={field.name}
									name={field.name}
									label={
										<div>
											<div className='text-start opacity-65 max-w-[200px] min-w-[200px] text-ellipsis overflow-hidden'>
												<span className='max-w-full whitespace-normal '>{field.label}</span>
												{field.required && <span className='ml-1 text-error'>*</span>}
											</div>
										</div>
									}
									rules={[{ required: field.required, message: t('error.required_field') }]}
									initialValue={values && values[field.name] ? values[field.name] : field.defaultValue}
								>
									<Input.TextArea size='large' rows={1} disabled={isDisabled} />
								</Form.Item>
							);
						case 'checkbox':
							return (
								<Form.Item
									key={field.name}
									label={
										<div className='text-start opacity-65 max-w-[200px] min-w-[200px] text-ellipsis overflow-hidden'>
											<span className='max-w-full whitespace-normal '>{field.label}</span>
											{field.required && <span className='ml-1 text-error'>*</span>}
										</div>
									}
									name={field.name}
									valuePropName='checked'
									rules={[{ required: field.required, message: t('error.required_field') }]}
									initialValue={values && values[field.name] ? !!values[field.name] : field.defaultValue}
								>
									<Checkbox disabled={isDisabled} />
								</Form.Item>
							);
						case 'select':
							return (
								<Form.Item
									key={field.name}
									name={field.name}
									label={
										<div className='text-start opacity-65 max-w-[200px] min-w-[200px] text-ellipsis overflow-hidden'>
											<span className='max-w-full whitespace-normal '>{field.label}</span>
											{field.required && <span className='ml-1 text-error'>*</span>}
										</div>
									}
									rules={[{ required: field.required, message: t('error.required_field') }]}
									initialValue={values && values[field.name] ? values[field.name] : field.defaultValue}
								>
									<Select options={field.options} disabled={isDisabled} />
								</Form.Item>
							);
						default:
							return null;
					}
				})}
			</Form>
			{!isDisabled && (
				<div className='flex gap-x-3 mt-6'>
					<Button type='primary' size='large' onClick={onFinish}>
						{t('continue')}
					</Button>
					<Button size='large' type='text' onClick={() => handleCancel()}>
						{t('cancel')}
					</Button>
				</div>
			)}
		</div>
	);
};

export const ArtefactUserInput = memo(ArtefactUserInputComponent);
