import { memo, ReactNode } from 'react';
import { useTranslate } from '../../lib/i18n';

interface IOauthClientBtn {
	clientName: string;
	oauthUrl: string;
	logo?: ReactNode;
}

const OauthClientBtnComponent = ({ clientName, oauthUrl, logo }: IOauthClientBtn) => {
	const t = useTranslate();
	return (
		<div className='py-1 px-4 bg-fill-tertiary max-w-[160px] min-h-[50px] flex gap-x-2 items-center rounded-md'>
			{logo}
			<div className='flex gap-y-1 flex-col overflow-hidden'>
				<span className='text-sm max-w-full overflow-hidden text-ellipsis'>{clientName}</span>
				<a className='text-primary hover:text-primary-active text-xs' href={oauthUrl}>
					{t('authorisation')}
				</a>
			</div>
		</div>
	);
};

export const OauthClientBtn = memo(OauthClientBtnComponent);
