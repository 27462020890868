import { entity } from 'simpler-state';

import {
	login as loginApi,
	signup as signupApi,
	signupDemo as signupDemoApi,
	logout as logoutApi,
	forgotPassword as forgotPasswordApi,
	resetPassword as resetPasswordApi,
	getUserData,
	setOnboarded,
	setDefaultThreadModel
} from '../api/userApi';
import { TUserOauthClient, TDefaultThreadModel } from './types';
import { formatBalance } from 'shared/lib/formatBalance';
import { BALANCE_MULTIPLIER } from 'shared';

export const email = entity('');
export const userId = entity<string | null>(null);
export const userAvatar = entity<{ id: string; url: string } | null>(null);
export const isLoggedIn = entity(false);
export const userType = entity<'registered' | 'demo' | null>(null);
export const balance = entity<number | string>(0);
export const userOauthClients = entity<TUserOauthClient | null>(null);
export const telegramToken = entity<string | null>(null);
export const defaultThreadModel = entity<TDefaultThreadModel | null>(null);
export const onboarded = entity<Record<string, boolean> | null>(null);
export const reset = () => {
	email.set('');
	userId.set(null);
	isLoggedIn.set(false);
	userType.set(null);
	balance.set(0);
};

export const updateUserBalance = async () => {
	try {
		const data = await getUserData();
		let totalBalance = data?.user?.balance + data?.user?.purchasedTokens;
		if (typeof data?.user?.freeTokens === 'number') {
			totalBalance += data?.user?.freeTokens;
		}
		balance.set(formatBalance(totalBalance * BALANCE_MULTIPLIER));
	} catch (e) {
		console.log('Balance update error');
	}
};

export const updateData = async () => {
	const data = await getUserData();
	defaultThreadModel.set(data?.user?.defaultThreadModel);
	email.set(data?.user?.email);
	userId.set(data?.user?.id);
	telegramToken.set(data?.user?.telegramToken);
	userOauthClients.set(data.user?.meta?.oauth ?? null);
	userAvatar.set(data?.user?.avatar ?? null);
	try {
		if (data?.user?.email) {
			(window as any).carrotquest.identify([{ op: 'update_or_create', key: '$email', value: data?.user?.email }]);
		}
	} catch (e) {
		console.error(e);
	}

	let totalBalance = data?.user?.balance + data?.user?.purchasedTokens;
	if (typeof data?.user?.freeTokens === 'number') {
		totalBalance += data?.user?.freeTokens;
	}
	balance.set(formatBalance(totalBalance * BALANCE_MULTIPLIER));

	isLoggedIn.set(data?.user?.type === 'registered');
	userType.set(data?.user?.type);
	onboarded.set(data?.user?.onboarded ?? null);
};

export const login = async (login: string, password: string) => {
	const data = await loginApi(login, password);
	try {
		if (data?.user?.id && data?.user?.carrotQuestHash) {
			(window as any).carrotquest.auth(data?.user?.id, data?.user?.carrotQuestHash);
		}
	} catch (e) {
		console.error(e);
	}
};

export const signupUser = async (
	login: string,
	password: string,
	policy: boolean,
	phone: string,
	promoCode?: string
) => {
	const data = await signupApi(login, password, policy, phone, promoCode);
	try {
		if (data?.user?.id && data?.user?.carrotQuestHash) {
			(window as any).carrotquest.auth(data?.user?.id, data?.user?.carrotQuestHash);
		}
	} catch (e) {
		console.error(e);
	}
};

export const signupDemoUser = async () => {
	const data = await signupDemoApi();
	try {
		if (data?.user?.id && data?.user?.carrotQuestHash) {
			(window as any).carrotquest.auth(data?.user?.id, data?.user?.carrotQuestHash);
		}
	} catch (e) {
		console.error(e);
	}
};

export const forgotPassword = async (email: string) => {
	await forgotPasswordApi(email);
};

export const resetPassword = async (token: string, password: string) => {
	await resetPasswordApi(token, password);
};

export const logoutUser = async () => {
	await logoutApi();
	reset();
};

export const updateUserOnboarding = async (pageName: string, userId: string) => {
	try {
		const { data } = await setOnboarded(pageName, userId);
		const newOnboarding = data?.doc?.onboarded ?? {};
		onboarded.set(newOnboarding);
	} catch (e) {
		const error = e as Error;
		throw new Error(error.message);
	}
};

export async function updateDefaultThreadModel(modelId: string, userId: string) {
	const { data } = await setDefaultThreadModel(modelId, userId);
	defaultThreadModel.set(data?.doc?.defaultThreadModel);
}
