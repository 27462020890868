import React, { FC, useEffect, useState } from 'react';
import { isLoggedIn, updateData } from 'entities/user';

type TAppLoaderProps = {
	children?: React.ReactNode;
};
export const AppLoader: FC<TAppLoaderProps> = ({ children }) => {
	const isUserLoggedIn = isLoggedIn.use();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setIsLoading(true);
		Promise.all([updateData()]).finally(() => {
			setIsLoading(false);
		});
	}, [isUserLoggedIn]);

	return <>{isLoading ? null : children}</>;
};
