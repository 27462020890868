import { createSlice } from '@reduxjs/toolkit';
import { userApi } from '../api/userApi';
import { TUser } from './types';

export type TUserState = {
	user: TUser | null;
};

const initialState: TUserState = {
	user: null
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(userApi.endpoints.getUser.matchFulfilled, (state, action) => {
			state.user = action.payload.user;
		});
	}
});
