import { baseApi } from 'shared/api/baseApi';
import { MODELS_LIST_TAG, LLM_MODELS_LIST_TAG } from 'shared/api/tags';
import { TLlmModel, TModel } from '../model/types';
import { TModelsDTO, TLlmModelsDTO } from './type';

export const modelsApi = baseApi.injectEndpoints({
	endpoints: (build) => ({
		getModels: build.query<TModel[], void>({
			query: () => ({
				url: `models`
			}),
			providesTags: [MODELS_LIST_TAG],
			transformResponse: (response: TModelsDTO) => {
				return response.docs.map((model) => ({ value: model.id, label: model.name, type: 'model', ...model }));
			}
		}),
		getLlmModels: build.query<TLlmModel[], void>({
			query: () => ({
				url: `llm_models`
			}),
			providesTags: [LLM_MODELS_LIST_TAG],
			transformResponse: (response: TLlmModelsDTO) => {
				return response.docs;
			}
		})
	})
});

export const { useGetModelsQuery, useGetLlmModelsQuery } = modelsApi;
