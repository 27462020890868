import React, { memo, useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { useTranslate } from 'shared/lib/i18n';
import { useGetVaultsQuery, useLazyGetVaultQuery } from 'entities/vaults';

interface IVaultSelect {
	name: string[];
	onChange: (value: any) => void;
	initialValue?: any;
}

const VaultSelectComponent = ({ name, onChange, initialValue }: IVaultSelect) => {
	const t = useTranslate();
	const [vaultOptions, setVaultOptions] = useState<{ value: string; label: string }[]>([]);
	const [getVault] = useLazyGetVaultQuery();
	// TODO remove hardcoded vaults per page when add Select with infinity scroll
	const { vaults } = useGetVaultsQuery(
		{
			page: 1,
			vaultsPerPage: 10000
		},
		{
			selectFromResult: ({ data, isLoading }) => ({
				vaults: data?.docs ?? [],
				isLoading
			})
		}
	);

	useEffect(() => {
		const setVaultsOptions = async (selectedVaultId: string) => {
			const selectedVault = await getVault(selectedVaultId).unwrap();
			if (vaults.find((vault) => vault.id === selectedVault.id)) {
				setVaultOptions([...vaults.map((vault) => ({ value: vault.id, label: vault.name }))]);
			} else {
				setVaultOptions([
					{ value: selectedVault.id, label: selectedVault.name },
					...vaults.map((vault) => ({ value: vault.id, label: vault.name }))
				]);
			}
		};
		if (initialValue?.id) {
			setVaultsOptions(initialValue?.id);
		} else {
			setVaultOptions([...vaults.map((vault) => ({ value: vault.id, label: vault.name }))]);
		}
	}, [vaults, initialValue]);

	return (
		<Form.Item
			label={
				<span>
					{t('vault')}
					<span className='ml-1 text-error'>*</span>
				</span>
			}
			name={name}
			initialValue={initialValue}
			rules={[{ required: true, message: t('error.required_field') }]}
		>
			<Select onChange={onChange} options={vaultOptions} />
		</Form.Item>
	);
};

export const VaultSelect = memo(VaultSelectComponent);
