import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './axiosBaseQuery';
import {
	APPS_LIST_TAG,
	PROJECTS_LIST_TAG,
	APP_TAGS_TAG,
	FAVOURITE_APPS_LIST_TAG,
	APP_TAG,
	THREAD_TAG,
	THREADS_LIST_TAG,
	MODELS_LIST_TAG,
	LLM_MODELS_LIST_TAG,
	VAULTS_LIST_TAG,
	NEW_MESSAGE_TAG,
	THREAD_MESSAGES_TAG,
	THREADS_HISTORY_TAG,
	PROJECT_TAG,
	OAUTH_CLIENTS_TAG,
	APP_PROJECTS_TAG,
	USER_TAG,
	SUBSCRIBES_TAG,
	SUBSCRIBES_URL_TAG,
	PRODUCTS_TAG,
	FAVOURITE_VAULTS_TAG,
	VAULT_TAG,
	GENERATION_TYPES_TAG,
	VAULT_TAGS_TAG
} from './tags';

export const baseApi = createApi({
	baseQuery: axiosBaseQuery(),
	tagTypes: [
		USER_TAG,
		APPS_LIST_TAG,
		PROJECTS_LIST_TAG,
		APP_PROJECTS_TAG,
		PROJECT_TAG,
		APP_TAGS_TAG,
		FAVOURITE_APPS_LIST_TAG,
		FAVOURITE_VAULTS_TAG,
		APP_TAG,
		THREAD_TAG,
		THREADS_LIST_TAG,
		MODELS_LIST_TAG,
		VAULTS_LIST_TAG,
		NEW_MESSAGE_TAG,
		THREAD_MESSAGES_TAG,
		THREADS_HISTORY_TAG,
		LLM_MODELS_LIST_TAG,
		OAUTH_CLIENTS_TAG,
		SUBSCRIBES_TAG,
		PRODUCTS_TAG,
		SUBSCRIBES_URL_TAG,
		VAULT_TAG,
		GENERATION_TYPES_TAG,
		VAULT_TAGS_TAG
	],
	endpoints: () => ({})
});
