import { Link } from 'react-router-dom';
import { memo } from 'react';

export const ThreadTag = memo((props: { title: string; threadId: string }) => {
	return (
		<Link
			to={`/thread/${props.threadId}`}
			style={{ wordBreak: 'break-word' }}
			className={`px-4 py-2 block rounded-lg bg-gray-bg hover:bg-gray-bg-hover cursor-pointer text-left`}
		>
			{props.title}
		</Link>
	);
});
